import React from 'react';
import {
  Card as AppCard
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  card: {
    margin: '16px',
    padding: '16px',
    minWidth: '300px',
  },
  button: {
    marginTop: '16px',
    marginRight: '8px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const Card: React.FC<any> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <AppCard className={classes.card}>
      {children}
    </AppCard>
  );
};

export default Card