import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import Home from './pages/Home';
import AppLayout from './components/AppLayout';
import { ProtectedRoute, UnprotectedRoute } from './components/common';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthProvider } from './context/Auth/AuthContext';
import Dashboard from './pages/Dashboard';
import Brokers from './pages/Brokers';
import Strategies from './pages/Strategies';
import Reports from './pages/Reports';
import KiteLoginHandler from './pages/KiteLoginHandler/KiteLoginHandler';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppLayout>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path="/login" element={<UnprotectedRoute><Login /></UnprotectedRoute>} />
              <Route path="/register" element={<UnprotectedRoute><Register /></UnprotectedRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/brokers" element={<ProtectedRoute><Brokers /></ProtectedRoute>} />
              <Route path="/strategies" element={<ProtectedRoute><Strategies /></ProtectedRoute>} />
              <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
              <Route path="/brokers/login" element={<ProtectedRoute><KiteLoginHandler/></ProtectedRoute>} />
            </Routes>
          </AppLayout>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
