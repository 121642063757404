import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../../context/Auth/AuthContext';
import { ProtectedRouteProps } from './ProtectedRouteInterface';

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {

    const { isAuthenticated, loading } = useAuth();
    
    if (loading) {
        return <div>Loading...</div>; // Render loading state while validating token
    }
    
    return isAuthenticated ? (
        <React.Fragment>
            {React.isValidElement(children) ? children : <Outlet />}
        </React.Fragment>
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoute;