import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

type Timestamp = {
  in_time: string;
  exit_time: string | null;
};

type Position = {
  script: string;
  transaction_type: string;
  entry_price: number;
  exit_price: number | null;
  ltp: number | null;
  pnl: number;
  quantity: number;
  status: string;
  timestamp: Timestamp;
};

type PositionsData = {
  positions: Position[];
};

type StrategyDetails = {
  strategy_id: string;
  strategy_name: string;
  broker_id: string;
  broker_name: string;
  broker_client_id: string;
  deployment_mode: string;
  broker_strategy_id: string;
  status: boolean;
  strategy_pnl: number;
  positions: PositionsData;
};

type BrokerData = {
  broker_id: string;
  broker_name: string;
  broker_client_id: string;
  strategies: StrategyDetails[];
};

type ApiResponse = {
  data: {
    data: StrategyDetails;
  };
};

const StrategyTable: React.FC = () => {
  const [brokers, setBrokers] = useState<BrokerData[]>([]);

  useEffect(() => {
    const newSocket = io('http://64.227.154.101:5000', {
      withCredentials: true,
      transports: ['websocket'],
    });

    newSocket.on('connect', () => {
      console.log('Socket connected successfully.');
    });

    newSocket.on('live_pnl', (data: ApiResponse) => {
      // console.log('Live PnL data received:', data);
      if (data?.data?.data) {
        const newStrategy = data.data.data;
        setBrokers((prevBrokers) => {
          const existingBrokerIndex = prevBrokers.findIndex(
            (broker) => broker.broker_id === newStrategy.broker_id
          );

          if (existingBrokerIndex !== -1) {
            // Broker exists, check if strategy exists under this broker
            const updatedBrokers = [...prevBrokers];
            const existingStrategyIndex = updatedBrokers[
              existingBrokerIndex
            ].strategies.findIndex(
              (strategy) => strategy.strategy_id === newStrategy.strategy_id
            );

            if (existingStrategyIndex !== -1) {
              // Strategy exists under this broker, update it
              updatedBrokers[existingBrokerIndex].strategies[
                existingStrategyIndex
              ] = newStrategy;
            } else {
              // Add the new strategy to the existing broker
              updatedBrokers[existingBrokerIndex].strategies.push(newStrategy);
            }

            return updatedBrokers;
          } else {
            // Add the new broker with its strategy
            return [
              ...prevBrokers,
              {
                broker_id: newStrategy.broker_id,
                broker_name: newStrategy.broker_name,
                broker_client_id: newStrategy.broker_client_id,
                strategies: [newStrategy],
              },
            ];
          }
        });
      } else {
        console.error('Received invalid data:', data);
      }
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    return () => {
      newSocket.close();
    };
  }, []);

  if (!brokers.length) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
      {brokers.map((broker) => (
        <Box key={broker.broker_id} mb={4}>
          <Typography variant="h5" gutterBottom>
            Broker: {broker.broker_name} ({broker.broker_client_id})
          </Typography>
          {broker.strategies.map((strategy) => (
            <Box key={`${broker.broker_id}-${strategy.strategy_id}`} mb={3}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                  {strategy.strategy_name}
                </Typography>
                <Typography variant="h6" color={strategy.strategy_pnl >= 0 ? 'green' : 'red'}>
                  PNL: ₹ {strategy.strategy_pnl.toFixed(2)}
                </Typography>
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="strategy positions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Script</TableCell>
                      <TableCell align="center">Transaction</TableCell>
                      <TableCell align="center">In Time</TableCell>
                      <TableCell align="center">Entry Price</TableCell>
                      <TableCell align="center">Exit Price</TableCell>
                      <TableCell align="center">Exit Time</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">LTP</TableCell>
                      <TableCell align="center">PNL</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {strategy.positions.positions.map((position, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {position.script}
                        </TableCell>
                        <TableCell align="center">{position.transaction_type}</TableCell>
                        <TableCell align="center">{position.timestamp.in_time}</TableCell>
                        <TableCell align="center">{position.entry_price}</TableCell>
                        <TableCell align="center">{position.exit_price ?? 'N/A'}</TableCell>
                        <TableCell align="center">{position.timestamp.exit_time ?? 'N/A'}</TableCell>
                        <TableCell align="center">{position.quantity}</TableCell>
                        <TableCell align="center">{position.ltp ?? 'N/A'}</TableCell>
                        <TableCell align="center" sx={{ color: position.pnl >= 0 ? 'green' : 'red' }}>
                          {position.pnl.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">{position.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                  Running
                </Button>
                <Button variant="contained" color="secondary">
                  Square Off
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default StrategyTable;
