import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { fetchBrokers } from '../Brokers/BrokersSlice';
import { editBroker, saveAccessToken } from '../Brokers/BrokerService';

const KiteLoginHandler: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tokenProcessed, setTokenProcessed] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { brokers } = useSelector((state: RootState) => state.brokers);

    useEffect(() => {
        dispatch(fetchBrokers());
    }, [dispatch]);

    useEffect(() => {
        if (!brokers.length) return;

        const queryParams = new URLSearchParams(location.search);
        const requestToken = queryParams.get('request_token');
        const status = queryParams.get('status');
        const id = localStorage.getItem('broker_id')

        const fetchAccessToken = async () => {
            if (requestToken && status === 'success' && id &&!tokenProcessed) {
                await saveAccessToken(id, requestToken)
                const updatedBroker = brokers.find((broker) => broker.id === id);
                if (updatedBroker) {
                    const brokerWithUpdatedStatus = { ...updatedBroker, is_terminal_active: true };
                    await editBroker(brokerWithUpdatedStatus);
                }
                setTokenProcessed(true)
                navigate('/dashboard')
            }
        }
        fetchAccessToken()
    }, [location.search, navigate, tokenProcessed, brokers]);

    return (
        <div>
            <h2>Processing Kite Login...</h2>
        </div>
    );
};

export default KiteLoginHandler;
