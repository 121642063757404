import React, { useCallback, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { InputField } from '../../components/common';

type Tick = {
    tradable: boolean;
    mode: string;
    instrument_token: number;
    last_price: number;
}

const Dashboard: React.FC = () => {
    const [inputValue, setInputValue] = useState('');
    const [socket, setSocket] = useState<any>(null);
    const [marketData, setMarketData] = useState<Record<number, Tick>>({});

    useEffect(() => {
        const newSocket = io('http://64.227.154.101:5000', {
            withCredentials: true,
            transports: ['websocket'],
        });
        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log('Socket connected successfully.');
        });

        newSocket.on('market_data', (data) => {
            if (data?.data && data?.data?.instrument_token !== undefined) {
                // console.log('Market Data received:', data.data.instrument_token);
                setMarketData(prevData => ({
                    ...prevData,
                    [data.data.instrument_token]: data.data,
                }));
            } else {
                console.error('Received invalid data:', data);
            }
        });

        // newSocket.on('live_pnl', (data) => {
        //     console.log('Live Pnl data received:', data);
        //     // if (data?.data && data?.data?.instrument_token !== undefined) {
        //     //     console.log('Market Data received:', data.data.instrument_token);
        //     //     setMarketData(prevData => ({
        //     //         ...prevData,
        //     //         [data.data.instrument_token]: data.data,
        //     //     }));
        //     // } else {
        //     //     console.error('Received invalid data:', data);
        //     // }
        // });

        newSocket.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);
        });

        return () => {
            newSocket.close();
        };
    }, []); // Removed marketData from dependencies to avoid reinitializing socket


    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        console.log('Sending data:', inputValue);
        const socket = io('http://64.227.154.101:5000');
        socket.emit('custom_event', { data: inputValue });
    }, [inputValue]);

    // Log marketData whenever it changes
    // useEffect(() => {
    //     console.log('Updated marketData:', marketData);
    // }, [marketData]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <InputField
                label="Enter message"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Send to Server
            </Button>

            {/* <div>
                {marketData ? (
                    <pre>{JSON.stringify(marketData)}</pre>
                ) : (
                    <p>No market data received yet.</p>
                )}
            </div> */}

            {Object.keys(marketData).length > 0 ? (
                Object.keys(marketData).map(token => {
                    const data = marketData[token];
                    return (
                        <Card key={token} sx={{ minWidth: 275, margin: 2 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Tradable Information
                                </Typography>
                                <Box mt={2}>
                                    <Typography variant="body1">
                                        <strong>Tradable:</strong> {data.tradable ? 'Yes' : 'No'}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Mode:</strong> {data.mode}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Instrument Token:</strong> {data.instrument_token}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Last Price:</strong> {data.last_price}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    );
                })
            ) : (
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Waiting for market data...
                </Typography>
            )}
        </Box>
    );
};

export default Dashboard;
