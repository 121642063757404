// src/components/common/UnprotectedRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../context/Auth/AuthContext';
import { UnprotectedRouteProps } from './UnprotectedRouteInterface';

const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

export default UnprotectedRoute;
