import React, { useState } from 'react';
import { Button, Box, Typography, Container, Alert, Snackbar } from '@mui/material';
import { ButtonLink, InputField } from '../../components/common';
import { axiosInstance } from '../../utils';
import { FormData, FormErrors } from './RegisterInterfaces';
import { validateForm } from './RegisterValidation';
import { registerBoxStyles } from './RegisterStyles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const Register: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        address: '',
    });

    const [errors, setErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            // Ensure the phone number contains only digits and is at most 10 characters long
            const phoneNumber = value.replace(/\D/g, '').slice(0, 10);
            setFormData({ ...formData, [name]: phoneNumber });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (date instanceof Date && !isNaN(date.getTime())) {
            setFormData({
                ...formData,
                date_of_birth: date.toISOString().split('T')[0] // Format the date as YYYY-MM-DD
            });
        } else {
            // Handle invalid date input
            setFormData({
                ...formData,
                date_of_birth: ''
            });
        }
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const tempErrors = validateForm(formData);
        setErrors(tempErrors);
        if (Object.keys(tempErrors).length > 0) return;

        setLoading(true);

        try {
            const response = await axiosInstance.post('/auth/register', formData);
            if (response.status === 201) {
                setLoading(false);
                // Reset the form data
                setFormData({
                    username: '',
                    email: '',
                    password: '',
                    first_name: '',
                    last_name: '',
                    date_of_birth: '',
                    phone_number: '',
                    address: '',
                    confirmPassword: ''
                });
                // Show success message
                setSnackbarMessage("Registration successful, please sign in.");
                setOpenSnackbar(true);

                // Redirect to sign-in page after a delay
                setTimeout(() => {
                    window.location.href = '/login';  // Adjust the path if necessary
                }, 2000); // Redirect after 2 seconds
            }

        } catch (err: any) {
            setLoading(false);
            console.error("error: ", err)
            setErrors({ api: err.response?.data?.message || 'Failed to register' });
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container component="main" maxWidth="xs">
                <Box sx={registerBoxStyles}>
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            value={formData.username}
                            onChange={handleChange}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="confirmPassword"
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            autoComplete="current-password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            name="first_name"
                            autoComplete="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            name="last_name"
                            autoComplete="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                        />
                        <DatePicker
                            label="Date of Birth"
                            value={formData.date_of_birth ? new Date(formData.date_of_birth) : null}
                            onChange={handleDateChange}
                            sx={{ width: '100%' }}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="phone_number"
                            label="Phone Number"
                            name="phone_number"
                            autoComplete="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <InputField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="address"
                            label="Address"
                            name="address"
                            autoComplete="address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                        {Object.keys(errors).map((key) => (
                            <Alert key={key} severity="error" sx={{ mt: 2 }}>
                                {errors[key]}
                            </Alert>
                        ))}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                        >
                            Register
                        </Button>
                        <Box textAlign="center">
                            <ButtonLink to="/login" color='primary' variant="text">
                                {"Already have an account? Sign in"}
                            </ButtonLink>
                        </Box>
                    </Box>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackbar(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>

                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default Register;
