export const validateBrokersForm = (formData: { broker_id: string; api_key: string; api_secret: string }) => {
    let errors: { [key: string]: string } = {};

    if (!formData.broker_id) {
        errors.broker_id = 'Broker ID is required';
    }

    if (!formData.api_key) {
        errors.api_key = 'API Key is required';
    }

    if (!formData.api_secret) {
        errors.api_secret = 'API Secret Key is required';
    }

    return errors;

}