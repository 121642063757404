import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { axiosInstance } from '../../utils';

interface OptionData {
  strikePrice: number;
  lastPrice: number;
  greeks: {
    IV: number;
    Delta: number;
    Gamma: number;
    Theta: number;
    Vega: number;
    Rho: number;
  };
}

interface OptionChain {
  [key: string]: OptionData;
}

interface Props {
  symbol: string;
  expiryDate: string;
}

const OptionChainTable: React.FC<Props> = ({ symbol, expiryDate }) => {
  const [optionChain, setOptionChain] = useState<OptionChain>({});

  const fetchOptionChain = async () => {
    try {
      const response = await axiosInstance.post<OptionChain>('/api/optionchain', { symbol, expiry_date: expiryDate });
      setOptionChain(response.data);
    } catch (error) {
      console.error('Error fetching option chain:', error);
    }
  };

  useEffect(() => {
    if (symbol && expiryDate) {
      fetchOptionChain();
    }
  }, [symbol, expiryDate]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="option chain table">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell align="right">Strike Price</TableCell>
            <TableCell align="right">Last Price</TableCell>
            <TableCell align="right">IV</TableCell>
            <TableCell align="right">Delta</TableCell>
            <TableCell align="right">Gamma</TableCell>
            <TableCell align="right">Theta</TableCell>
            <TableCell align="right">Vega</TableCell>
            <TableCell align="right">Rho</TableCell>
          </TableRow>
        </TableHead>
        {/* <TableBody>
          {Object.entries(optionChain).map(([key, option]) => (
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell align="right">{option.strikePrice}</TableCell>
              <TableCell align="right">{option.lastPrice}</TableCell>
              <TableCell align="right">{option.greeks.IV.toFixed(2)}</TableCell>
              <TableCell align="right">{option.greeks.Delta.toFixed(2)}</TableCell>
              <TableCell align="right">{option.greeks.Gamma.toFixed(2)}</TableCell>
              <TableCell align="right">{option.greeks.Theta.toFixed(2)}</TableCell>
              <TableCell align="right">{option.greeks.Vega.toFixed(2)}</TableCell>
              <TableCell align="right">{option.greeks.Rho.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
      </Table>
    </TableContainer>
  );
};

export default OptionChainTable;
