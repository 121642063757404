import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#008080', // Teal
    },
    secondary: {
      main: '#003366', // Deep Blue
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#FFFFFF', // White
      paper: '#F4F4F4', // Light Grey
    },
    text: {
      primary: '#333333', // Charcoal Grey
      secondary: '#575757'
    }
  },
});

export default theme;
