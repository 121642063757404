// RegisterStyles.ts

import { SxProps } from '@mui/system';

export const registerBoxStyles: SxProps = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
