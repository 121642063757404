import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const SecondaryCTA: React.FC = () => {
  return (
    <Box textAlign="center" my={5}>
      <Typography variant="h5" gutterBottom>Ready to take the next step?</Typography>
      <Button variant="outlined" color="secondary">Contact Us</Button>
    </Box>
  );
};

export default SecondaryCTA;
