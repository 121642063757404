import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Box } from '@mui/material';
import { useAuth } from '../../context/Auth/AuthContext';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  const isHomePage = location.pathname === '/'

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header/>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {isAuthenticated && !isHomePage && <Sidebar />}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, 
            mt: '64px'
          }}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default AppLayout;
