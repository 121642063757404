import React, { useEffect, useState } from 'react';
import { AlertColor, Box, Button, IconButton, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { AlertSnackBar, InputField } from '../../components/common';
import { validateBrokersForm } from './BrokersValidation';
import { BrokerInterface } from './BrokerInterface';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import { fetchBrokers, addBroker, deleteBroker } from './BrokersSlice';
import { getKiteLoginUrl, editBroker } from './BrokerService';

const Brokers: React.FC = () => {
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState<BrokerInterface>({ id: '', broker_id: '', api_key: '', api_secret: '', is_terminal_active: false, is_trading_engine_active: false });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [generalMessage, setGeneralMessage] = useState<string>('')
    const [severity, setSeverity] = useState<AlertColor>('info')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedBroker, setSelectedBroker] = useState<BrokerInterface | null>(null);

    const dispatch = useDispatch<AppDispatch>();
    const { brokers, loading, error } = useSelector((state: RootState) => state.brokers);

    useEffect(() => {
        dispatch(fetchBrokers())
    }, [dispatch]);

    const handleAddBrokerClick = () => {
        setShowForm(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const tempErrors = validateBrokersForm(formData);

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            return;
        }

        try {
            // Submit form logic here
            dispatch(addBroker(formData))
            // Reset form and errors
            setFormData({ id: '', broker_id: '', api_key: '', api_secret: '', is_terminal_active: false, is_trading_engine_active: false });
            setErrors({});
            setShowForm(false);
            setGeneralMessage('Broker added successfully')
            setSeverity('success')
        } catch (error) {
            setGeneralMessage('Failed to add broker')
            setSeverity('error')
        }
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, broker: BrokerInterface) => {
        setAnchorEl(event.currentTarget);
        setSelectedBroker(broker);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedBroker(null);
    };

    const handleDelete = async () => {
        if (selectedBroker) {
            try {
                // Handle delete logic here
                await dispatch(deleteBroker(selectedBroker.id))
                setGeneralMessage('Broker deleted successfully');
                setSeverity('success');
            } catch (error) {
                setGeneralMessage('Failed to delete broker');
                setSeverity('error');
            }
        }
        handleMenuClose();
    };

    const handleTerminalSwitch = async (broker: BrokerInterface, is_terminal_active: boolean) => {
        if (is_terminal_active) {
            try {
                const { login_url } = await getKiteLoginUrl(broker.id);
                localStorage.setItem('broker_id', broker.id)
                window.location.href = login_url;
            } catch (error) {
                console.error('Error fetching Kite login URL:', error);
            }
        } else {
            try {
                const updatedBroker = {
                    ...broker,
                    is_terminal_active: false
                };
                await editBroker(updatedBroker);
                dispatch(fetchBrokers());
            } catch (error) {
                console.error('Failed to update terminal status', error);
            }
        }
    }

    const handleEngineSwitchChange = async (broker: BrokerInterface, is_trading_engine_active: boolean) => {
        try {
            const updatedBroker = {
                ...broker,
                is_trading_engine_active
            };
            await editBroker(updatedBroker);
            dispatch(fetchBrokers());
        } catch (error) {
            console.error('Failed to update trading engine status', error);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h4" gutterBottom>
                    Brokers
                </Typography>
                <Button variant="contained" color="primary" onClick={handleAddBrokerClick}>
                    + Add Broker
                </Button>
            </Box>
            {showForm && (
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, margin: '0 auto' }}>
                    <InputField
                        label="Enter broker Id"
                        variant="outlined"
                        fullWidth
                        name="broker_id"
                        value={formData.broker_id}
                        onChange={handleChange}
                        error={!!errors.broker_id}
                        helperText={errors.broker_id}
                    />
                    <InputField
                        label="API KEY"
                        variant="outlined"
                        fullWidth
                        name="api_key"
                        value={formData.api_key}
                        onChange={handleChange}
                        error={!!errors.api_key}
                        helperText={errors.api_key}
                    />
                    <InputField
                        label="API SECRET KEY"
                        variant="outlined"
                        fullWidth
                        name="api_secret"
                        value={formData.api_secret}
                        onChange={handleChange}
                        error={!!errors.api_secret}
                        helperText={errors.api_secret}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            )}
            {brokers.length > 0 && (
                <Box sx={{ marginTop: 3 }}>
                    {brokers.map((broker: BrokerInterface, index: number) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2, borderBottom: '1px solid #ddd' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/path-to-zerodha-logo.png" alt="Zerodha" style={{ width: 50, height: 50, marginRight: 16 }} />
                                <Box>
                                    <Typography variant="h6">Zerodha</Typography>
                                    <Typography variant="body2">{broker.broker_id}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                                    <Typography variant="body2" sx={{ marginRight: 1 }}>Terminal</Typography>
                                    <Switch 
                                        checked={broker.is_terminal_active} 
                                        onChange={(e) => handleTerminalSwitch(broker, e.target.checked)}
                                        disabled={broker.is_terminal_active} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                                    <Typography variant="body2" sx={{ marginRight: 1 }}>Trading Engine</Typography>
                                    <Switch 
                                        checked={broker.is_trading_engine_active} 
                                        onChange={(e) => handleEngineSwitchChange(broker, e.target.checked)} 
                                        disabled={broker.is_trading_engine_active}/>
                                </Box>
                                <IconButton onClick={(event) => handleMenuOpen(event, broker)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
            <AlertSnackBar severity={severity} snackbarMessage={generalMessage} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {/* <MenuItem onClick={handleEdit}>Edit</MenuItem> */}
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Box>
    );
};

export default Brokers;
