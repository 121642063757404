import React from 'react';
import HeroSection from '../../components/HeroSection';
import FeaturesOverview from '../../components/FeaturesOverview';
import Testimonials from '../../components/Testimonials';
import SecondaryCTA from '../../components/SecondaryCTA';
import { Box } from '@mui/material';

const Home: React.FC = () => {
  return (
    <Box>
      <HeroSection />
      <FeaturesOverview />
      <Testimonials />
      <SecondaryCTA />
    </Box>
  );
};

export default Home;
