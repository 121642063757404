import React, { useEffect, useState } from 'react';
import {
    Box, CardContent,
    Typography,
    Grid,
    Button,
} from '@mui/material';
import Card from '../../components/Card';
import { StrategyInterface } from './StrategyInterface';
import { axiosInstance } from '../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    button: {
      marginTop: '16px',
      marginRight: '8px',
    },
    pos: {
      marginBottom: 12,
    },
  });
  

const StrategyList: React.FC = () => {

    const [strategies, setStrategies] = useState<StrategyInterface[]>([]);

    useEffect(() => {
        // Fetch the strategies from the API
        axiosInstance.get('/api/strategy')
            .then(response => {
                setStrategies(response.data); // Adjust based on your API response structure
            })
            .catch(error => {
                console.error('Failed to fetch strategies', error);
            });
    }, []);

    const classes = useStyles();

    return (
        <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Grid container spacing={2}>
                {strategies.map(({name, description, created_at, updated_at}) => (
                    <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {name}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            {description}
                        </Typography>
                        <Typography variant="body2" component="p">
                            Created At: {new Date(created_at).toLocaleString()}
                            <br />
                            Updated At: {new Date(updated_at).toLocaleString()}
                        </Typography>
                        <Button variant="contained" color="primary" className={classes.button}>
                            Deploy
                        </Button>
                    </CardContent>
                </Card>
                ))
                }
            </Grid>
        </Box>
    );
};

export default StrategyList;
