import { configureStore } from "@reduxjs/toolkit";
import brokersReducer from './pages/Brokers/BrokersSlice'

export const store = configureStore({
    reducer: {
        brokers: brokersReducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;