import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{
      py: 2,
      px: 2,
      mt: 'auto',
      textAlign: 'center',
      backgroundColor: (theme) => theme.palette.background.paper,
      borderTop: '1px solid #e0e0e0'
    }}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} DAC. All rights reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link color="inherit" href="#">Privacy Policy</Link> | <Link color="inherit" href="#">Terms of Use</Link>
      </Typography>
    </Box>
  );
};

export default Footer;
