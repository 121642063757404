import React from 'react';
import CustomTabs from '../../components/Tabs/Tabs';
import StrategyList from './StrategyList';
import StrategyTable from './DeployedStrategies';

const Strategies: React.FC = () => {
    const tabs = [
        {
            label: 'Strategies',
            content: <StrategyList/>,
        },
        {
            label: 'Deployed Strategies',
            content: <StrategyTable/>,
        }
    ];

    return <CustomTabs tabs={tabs} />;
};

export default Strategies;
