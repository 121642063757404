import { axiosInstance } from "../../utils";
import { BrokerInterface } from "./BrokerInterface";

const getBrokers = async (): Promise<BrokerInterface[]> => {
  const response = await axiosInstance.get<BrokerInterface[]>('/api/brokers');
  return response.data;
};

const addBroker = async (brokerData: BrokerInterface): Promise<BrokerInterface> => {
  const response = await axiosInstance.post<BrokerInterface>('/api/brokers', brokerData);
  return response.data;
};

const editBroker = async (brokerData: BrokerInterface): Promise<{message: string}> => {
    const response = await axiosInstance.put<{message: string}>('/api/brokers/'+brokerData.id, brokerData);
    return response.data;
}

const deleteBroker = async(id: string): Promise<string> => {
    const response = await axiosInstance.delete('/api/brokers/'+id)
    return id;
}

const getKiteLoginUrl = async (id: string): Promise<{login_url: string}> => {
  const response = await axiosInstance.post<{ login_url: string }>('/api/brokers/login_url', { id: id });
  return response.data;  
}

const saveAccessToken = async (id: string, request_token: string): Promise<{ message: string }> => {
  const response = await axiosInstance.post<{ message: string }>('/api/brokers/save_access_token', { id, request_token });
  return response.data;
};

const startTradingEngine = async (id: string) => {
  // const response = await axiosInstance.post<{ login_url: string }>('/api/brokers/login_url', { id: id });
  // return response.data;  
}


export { getBrokers, addBroker, editBroker, deleteBroker, getKiteLoginUrl, saveAccessToken };
