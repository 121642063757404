import React from 'react';
import { Box, Typography, Paper, Avatar, Grid } from '@mui/material';

const testimonials = [
  { name: 'Jane Doe', review: 'This platform has transformed my trading strategy.', avatar: '/path/to/avatar.jpg' },
  { name: 'John Smith', review: 'A must-use tool for serious traders.', avatar: '/path/to/avatar2.jpg' }
];

const Testimonials: React.FC = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Testimonials</Typography>
      <Grid container spacing={2}>
        {testimonials.map((testi, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper elevation={4} sx={{ padding: 2 }}>
              <Avatar src={testi.avatar} alt={testi.name} sx={{ width: 56, height: 56 }} />
              <Typography variant="subtitle1">{testi.name}</Typography>
              <Typography variant="body2">{testi.review}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;
