import axios from 'axios';

// Function to get the CSRF token from cookies
function getCsrfToken() {
  const name = 'csrf_access_token';
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return null;
}

// Create an axios instance with default settings
const axiosInstance = axios.create({
  baseURL: 'http://64.227.154.101:5000',
  withCredentials: true,
  xsrfCookieName: 'csrf_access_token', // The name of the cookie to use as a value for the xsrf token
  xsrfHeaderName: 'X-CSRF-TOKEN', // The name of the HTTP header that carries the xsrf token value
  headers: {
    'Accept': 'application/json, text/plain, */*',
  },
});

// Add a request interceptor to include the CSRF token and cookies
axiosInstance.interceptors.request.use(
  (config) => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
