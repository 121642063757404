import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils";
import { BrokerInterface } from "./BrokerInterface";
import { getBrokers, addBroker as addBrokerService, deleteBroker as deleteBrokerService} from "./BrokerService";

interface BrokerState {
    brokers: BrokerInterface[];
    loading: boolean;
    error: string | null;
  }

const initialState: BrokerState = {
  brokers: [],
  loading: false,
  error: null,
};

export const fetchBrokers = createAsyncThunk('brokers/fetchBrokers', async () => {
    const response = await getBrokers()
    return response as BrokerInterface[];
});

export const addBroker = createAsyncThunk('brokers/addBroker', async (brokerData: BrokerInterface) => {
    const response = await addBrokerService(brokerData)
    return response
});

export const deleteBroker = createAsyncThunk('brokers/deleteBroker', async (id: string) => {
    await deleteBrokerService(id)
    return id
});

const brokersSlice = createSlice({
    name: 'brokers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBrokers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBrokers.fulfilled, (state, action: PayloadAction<BrokerInterface[]>) => {
                state.loading = false;
                state.brokers = action.payload;
            })
            .addCase(fetchBrokers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch brokers';
            })
            .addCase(addBroker.fulfilled, (state, action: PayloadAction<BrokerInterface>) => {
                state.brokers.push(action.payload);
            })
            .addCase(deleteBroker.fulfilled, (state, action: PayloadAction<string>) => {
                state.brokers = state.brokers.filter((broker) => broker.id !== action.payload);
            })
            .addCase(deleteBroker.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to delete broker';
            });
    }
});

export default brokersSlice.reducer;