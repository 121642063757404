import React, { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { axiosInstance } from '../../utils';
import OptionChain from '../OptionChain';

interface SymbolData {
  [key: string]: string[];
}

const SymbolExpirySelector: React.FC = () => {
  const [symbols, setSymbols] = useState<SymbolData>({});
  const [selectedSymbol, setSelectedSymbol] = useState<string>('');
  const [selectedExpiry, setSelectedExpiry] = useState<string>('');
  const [expiries, setExpiries] = useState<string[]>([]);

  useEffect(() => {
    // Simulating fetching data
    const fetchData = async () => {
        const response = await axiosInstance.get<SymbolData>('/api/symbols?exchange=NFO') //hardcoding exchange=NFO as for now to display option chain
        setSymbols(response.data);
      };

    fetchData();
  }, []);

  const handleSymbolChange = (event: SelectChangeEvent<string>) => {
    const symbol = event.target.value;
    setSelectedSymbol(symbol);
    setExpiries(symbols[symbol] || []);
  };

  const handleExpiryChange = (event: SelectChangeEvent<string>) => {
    setSelectedExpiry(event.target.value);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="symbol-select-label">Symbol</InputLabel>
        <Select
          labelId="symbol-select-label"
          value={selectedSymbol}
          label="Symbol"
          onChange={handleSymbolChange}
        >
          {Object.keys(symbols).map(symbol => (
            <MenuItem key={symbol} value={symbol}>{symbol}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedSymbol && (
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
          <Select
            labelId="expiry-select-label"
            value={selectedExpiry}
            label="Expiry Date"
            onChange={handleExpiryChange}
          >
            {expiries.map(expiry => (
              <MenuItem key={expiry} value={expiry}>{expiry}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedSymbol && selectedExpiry && <OptionChain symbol={selectedSymbol} expiryDate={selectedExpiry}/>}
    </div>
  );
};

export default SymbolExpirySelector;
