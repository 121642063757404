import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/Auth/AuthContext';  // Adjust the import path according to your structure

const Header: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();  // Assuming `logout` is available from the context
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <AppBar 
      position="fixed" 
      color="default" 
      elevation={1}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" color="inherit" noWrap component={Link} to="/" sx={{ textDecoration: 'none' }}>
            DAC
          </Typography>
        </Box>
        <Box>
          {isAuthenticated ? (
            <>
              <Button color="primary" sx={{ margin: 1 }} onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button color="primary" component={Link} to="/login" sx={{ margin: 1 }}>
                Sign In
              </Button>
              <Button variant="contained" color="primary" component={Link} to="/register" sx={{ margin: 1 }}>
                Register
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
