import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const features = [
  { title: 'Real-time Data', description: 'Access up-to-the-minute market data.' },
  { title: 'Strategy Optimization', description: 'Optimize strategies with our advanced tools.' },
  { title: 'User Friendly', description: 'A user-friendly interface for all traders.' },
];

const FeaturesOverview: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">{feature.title}</Typography>
                <Typography>{feature.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesOverview;
