import React, { useState } from 'react';
import { Button, Box, Typography, Container, Alert } from '@mui/material';
import { ButtonLink, InputField } from '../../components/common';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/Auth/AuthContext';
import { validateEmail, validatePassword } from '../../utils';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }
        if (!validatePassword(password)) {
            setError("Password must be 8-12 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            return;
        }
        setLoading(true);
        setError('');

        try {
            await login(email, password)
            navigate('/dashboard');  // Redirect to a protected route
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to login');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
                    <InputField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <InputField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        Sign In
                    </Button>
                    <Box textAlign="center">
                        <ButtonLink to="/register" color='secondary' variant="text">
                            {"Don't have an account? Register"}
                        </ButtonLink>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
