// RegisterValidation.ts

import { validateEmail, validatePassword } from '../../utils';
import { FormData, FormErrors } from './RegisterInterfaces';

const phoneRegex = /^\d{10}$/;

export const validateForm = (formData: FormData): FormErrors => {
    let tempErrors: FormErrors = {};

    if (!formData.username || formData.username.length < 4 || formData.username.length > 50) {
        tempErrors['username'] = "Username must be between 4 and 50 characters";
    }
    if (!formData.email || !validateEmail(formData.email)) {
        tempErrors['email'] = "Enter a valid email";
    }
    if (!formData.password || !validatePassword(formData.password)) {
        tempErrors['password'] = "Password must be 8-12 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character";
    }
    if (!formData.confirmPassword || formData.password !== formData.confirmPassword) {
        tempErrors['confirmPassword'] = "Passwords do not match";
    }
    if (!formData.first_name || formData.first_name.length < 1 || formData.first_name.length > 50) {
        tempErrors['first_name'] = "First name is required and must be under 50 characters";
    }
    if (!formData.last_name || formData.last_name.length < 1 || formData.last_name.length > 50) {
        tempErrors['last_name'] = "Last name is required and must be under 50 characters";
    }
    if (!formData.phone_number || !phoneRegex.test(formData.phone_number)) {
        tempErrors['phone_number'] = "Phone number must be exactly 10 digits";
    }
    if (!formData.address) {
        tempErrors['address'] = "Address is required";
    }
    if (!formData.date_of_birth) {
        tempErrors['date_of_birth'] = "Date of birth is required";
    }

    return tempErrors;
};
