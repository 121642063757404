import React from 'react';
import SymbolExpirySelector from '../../components/SymbolExpirySelector';

const Reports: React.FC = () => {
  return( <>
      <div>Reports Page</div>
      <SymbolExpirySelector />
    </>);
};

export default Reports;
