import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// Extend the ButtonProps to include 'to' from RouterLinkProps
interface ButtonLinkProps extends ButtonProps, Pick<RouterLinkProps, 'to'> {}

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, children, ...props }) => {
  return (
    <Button component={RouterLink} to={to} {...props}>
      {children}
    </Button>
  );
};

export default ButtonLink;
