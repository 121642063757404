import React from 'react';
import { Alert, Snackbar } from "@mui/material"
import { useState } from "react";
import { AlertSnackBarProps } from "./AlertSnackBarInterface";


const AlertSnackBar:React.FC<AlertSnackBarProps> = ({ severity,snackbarMessage }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    return (
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    )
}

export default AlertSnackBar