import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { Box, Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'

const SideBar: React.FC = () => {
    const drawerWidth = 240; // Sidebar width
    const items = [
        {
            text: 'Dashboard',
            icon: <DashboardIcon />,
            path: '/dashboard'
        },
        {
            text: 'Brokers',
            icon: <AccountBalanceIcon />,
            path: '/brokers'
        },
        {
            text: 'Strategies',
            icon: <TrendingUpIcon />,
            path: '/strategies'
        },
        {
            text: 'Reports',
            icon: <AssessmentIcon />,
            path: '/reports'
        }
    ]

    return (
        <Drawer variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            mt: '64px' // Adjusted to account for header height
          },
        }}>
            <Box sx={{ width: 250}}>
                <List>
                    {items.map((item, index) => 
                    <ListItemButton key={index} component={Link} to={item.path}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItemButton>)}
                </List>
            </Box>
        </Drawer>
    )
}

export default SideBar